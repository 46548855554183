import React, { useEffect, useState } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Divider,
  Alert,
  Select,
  MenuItem,
  useTheme,
  useMediaQuery,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Stack,
  Collapse,
  IconButton,
} from "@mui/material";
import {
  ArrowForwardIos,
  ArrowUpward,
  Email,
  Message,
  Phone,
  WhatsApp,
} from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

function SwapDashboard() {
  const [activeTab, setActiveTab] = useState("County Match");
  const [swapsData, setSwapsData] = useState({
    alternative: [],
    ward: [],
    subcounty: [],
    county: [],
  });
  const [matchCounts, setMatchCounts] = useState({
    alternative: 0,
    ward: 0,
    subcounty: 0,
    county: 0,
  });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [column, setColumn] = useState("name");
  const [searchValue, setSearchValue] = useState("");
  const [refresh, setRefresh] = useState(false);
  const typeMap = {
    "Alternative Match": "alternative",
    "Ward Match": "ward",
    "SubCounty Match": "subcounty",
    "County Match": "county",
  };

  useEffect(() => {
    const fetchSwapsCounts = async () => {
      setIsLoading(true);
      try {
        const res = await fetch("/api/swaps/mystats", {
          credentials: "include",
        });
        setIsLoading(false);
        if (res.ok) {
          const data = await res.json();
          setMatchCounts(data);
        } else if (res.status === 401) {
          window.location.href = "/";
          throw new Error("Unauthorized access. Please log in.");
        } else if (res.status === 403) {
          window.location.href = "/";
          throw new Error(
            "Forbidden access. You do not have permission to view this resource."
          );
        } else {
          throw new Error("An error occurred while fetching data.");
        }
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchSwapsCounts();
  }, []);

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setSwapsData({
        alternative: [],
        ward: [],
        subcounty: [],
        county: [],
      });
      setTotalPages(1);
      const type = typeMap[activeTab];
      fetch(
        `/api/swaps/myswapsdata/${type}?searchColumn=${column}&searchValue=${value}`,
        {
          method: "get",
          credentials: "include",
        }
      )
        .then((res) => {
          if (res.ok) return res.json();
          else throw new Error();
        })
        .then((data) => {
          setSwapsData((prevData) => ({
            ...prevData,
            [type]: data.matches,
          }));
          setTotalPages(data.totalPages);
        })
        .catch(() => {
          setSwapsData({
            alternative: [],
            ward: [],
            subcounty: [],
            county: [],
          });
          setTotalPages(1);
        });
    } else {
      setRefresh(!refresh);
    }
  };

  useEffect(() => {
    const fetchSwapsByType = async () => {
      const type = typeMap[activeTab];

      try {
        const res = await fetch(
          `/api/swaps/myswapsdata/${type}?page=${page}&limit=10`,
          {
            credentials: "include",
          }
        );

        if (res.ok) {
          const data = await res.json();
          console.log(data);

          setSwapsData((prevData) => ({
            ...prevData,
            [type]: data.matches,
          }));
          setTotalPages(data.totalPages);
        } else if (res.status === 401) {
          window.location.href = "/";
          throw new Error("Unauthorized access. Please log in.");
        } else if (res.status === 403) {
          window.location.href = "/";
          throw new Error(
            "Forbidden access. You do not have permission to view this resource."
          );
        } else {
          throw new Error("An error occurred while fetching data.");
        }
      } catch (error) {
        console.error("Error fetching swap matches:", error);
      }
    };

    fetchSwapsByType();
  }, [activeTab, page, refresh]);

  const renderTable = (data) =>
    !isMobile ? (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SN</TableCell>
              <TableCell>Teacher</TableCell>
              <TableCell>Other Info</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Payment</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((swap, index) => {
              return (
                <SwapItem
                  key={index}
                  index={index}
                  page={page}
                  swap={swap}
                  isMobile={isMobile}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      data.map((swap, index) => {
        return (
          <SwapItem
            key={index}
            index={index}
            page={page}
            swap={swap}
            isMobile={isMobile}
          />
        );
      })
    );

  return (
    <Box sx={{ marginTop: isMobile ? "5rem" : "5rem" }}>
      <Stack spacing={3}>
        <Box
          variant="contained"
          orientation={isMobile ? "vertical" : "horizontal"}
          fullWidth={isMobile}
          sx={{ boxShadow: "none", display: "flex", gap: 1 }}
        >
          <Button
            size="small"
            sx={{
              textTransform: "capitalize",
              gap: 1,
              display: { xs: "block", md: "flex" },
            }}
            onClick={() => setActiveTab("County Match")}
            variant={activeTab === "County Match" ? "contained" : "outlined"}
          >
            <Chip
              size="small"
              sx={{
                p: 1,
                margin: "auto",
                fontSize: { xs: "12px", md: "14px" },
              }}
              color={matchCounts.countyMatches > 0 ? "success" : "warning"}
              label={matchCounts.countyMatches}
            />
            County
          </Button>
          <Button
            size="small"
            sx={{
              textTransform: "capitalize",
              gap: 1,
              display: { xs: "block", md: "flex" },
            }}
            onClick={() => setActiveTab("SubCounty Match")}
            variant={activeTab === "SubCounty Match" ? "contained" : "outlined"}
          >
            <Chip
              size="small"
              sx={{
                p: 1,
                margin: "auto",
                fontSize: { xs: "12px", md: "14px" },
              }}
              color={matchCounts.subCountyMatches > 0 ? "success" : "warning"}
              label={matchCounts.subCountyMatches}
            />
            SubCounty
          </Button>
          <Button
            size="small"
            sx={{
              textTransform: "capitalize",
              gap: 1,
              display: { xs: "block", md: "flex" },
            }}
            onClick={() => setActiveTab("Ward Match")}
            variant={activeTab === "Ward Match" ? "contained" : "outlined"}
          >
            <Chip
              size="small"
              sx={{
                p: 1,
                margin: "auto",
                fontSize: { xs: "12px", md: "14px" },
              }}
              color={matchCounts.wardMatches > 0 ? "success" : "warning"}
              label={matchCounts.wardMatches}
            />
            Ward
          </Button>
          <Button
            size="small"
            sx={{
              textTransform: "capitalize",
              gap: 1,
              display: { xs: "block", md: "flex" },
            }}
            onClick={() => setActiveTab("Alternative Match")}
            variant={
              activeTab === "Alternative Match" ? "contained" : "outlined"
            }
          >
            <Chip
              size="small"
              sx={{
                p: 1,
                margin: "auto",
                fontSize: { xs: "12px", md: "14px" },
              }}
              color={matchCounts.alternativeMatches > 0 ? "success" : "warning"}
              label={matchCounts.alternativeMatches}
            />
            Alternative
          </Button>
        </Box>

        <Box display="flex" gap={1}>
          <FormControl fullWidth>
            <InputLabel size="small">Search by...</InputLabel>
            <Select
              label="Search by..."
              size="small"
              onChange={(e) => setColumn(e.target.value)}
              value={column}
            >
              <MenuItem value="name">Name</MenuItem>
              <MenuItem value="phoneNumber">Phone</MenuItem>
              <MenuItem value="email">Email</MenuItem>
              <MenuItem value="currentCounty">Current County</MenuItem>
              <MenuItem value="currentSubCounty">Current SubCounty</MenuItem>
              <MenuItem value="currentWard">Current Ward</MenuItem>
              <MenuItem value="countyTo">Desired County</MenuItem>
              <MenuItem value="subCountyTo">Desired SubCounty</MenuItem>
              <MenuItem value="wardTo">Desired Ward</MenuItem>
              <MenuItem value="matchedSwapCountyTo">Matched County</MenuItem>
              <MenuItem value="matchedSwapSubCountyTo">
                Matched SubCounty
              </MenuItem>
              <MenuItem value="matchedSwapWardTo">Matched Ward</MenuItem>
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            size="small"
            label="Search..."
            value={searchValue}
            fullWidth
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Box>
      </Stack>

      <Box marginTop={2} minHeight="50vh">
        {activeTab === "Alternative Match" &&
          renderTable(swapsData.alternative)}
        {activeTab === "Ward Match" && renderTable(swapsData.ward)}
        {activeTab === "SubCounty Match" && renderTable(swapsData.subcounty)}
        {activeTab === "County Match" && renderTable(swapsData.county)}
        {isLoading && (
          <Box display="flex" height="100%" width="100%" alignItems="center">
            <CircularProgress sx={{ display: "block", margin: "auto" }} />
          </Box>
        )}
      </Box>
      <Box marginTop={2} display="flex" justifyContent="center">
        <Button
          disabled={page <= 1}
          onClick={() => setPage((prevPage) => prevPage - 1)}
        >
          Previous
        </Button>
        <Box marginX={2}>
          Page {page} of {totalPages}
        </Box>
        <Button
          disabled={page >= totalPages}
          onClick={() => setPage((prevPage) => prevPage + 1)}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}

export default SwapDashboard;

const SwapItem = (props) => {
  const { isMobile } = props;
  const [mePay, setMePay] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [matchedSwap, setMatchedSwap] = useState(null);
  const [openMatchedSwapDialog, setOpenMatchedSwapDialog] = useState(false);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    fetch(
      `/api/payments/getpayments/${props.swap?.ID}/${props.swap?.MatchedSwapID}`,
      {
        credentials: "include",
      }
    )
      .then((res) => {
        if (res.ok) return res.json();
        if (res.status === 401 || res.status === 403) {
          window.location.href = "/";
          throw new Error("Unauthorized or forbidden access.");
        }
        throw new Error("Error fetching data.");
      })
      .then((data) => {
        if (data.MePay.length > 0) setMePay(data.MePay[0]);
      })
      .catch((e) => console.error(e));
  }, [props.swap]);

  const handleClick = () => {
    if (mePay && mePay?.Status === "Pending") setOpenDialog(true);
  };

  const handleStatusChange = (event) => setStatus(event.target.value);

  const handleSubmit = () => {
    setLoading(true);
    fetch(`/api/payments/${mePay.ID}`, {
      method: "PUT",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ Status: status }),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        setAlertMessage({
          type: "success",
          text: "Payment status updated successfully!",
        });
        setMePay(data);
        setTimeout(() => setOpenDialog(false), 1000);
      })
      .catch(() => {
        setLoading(false);
        setAlertMessage({
          type: "warning",
          text: "An error occurred. Please try again.",
        });
      });
  };

  const getButtonText = () =>
    !mePay
      ? "No Action"
      : mePay.Status === "Pending"
      ? "Review Payment"
      : "No Action";

  const handleViewMatchedSwap = () => {
    fetch(`/api/swaps/${props.swap?.MatchedSwapID}`, { credentials: "include" })
      .then((res) => res.json())
      .then((data) => setMatchedSwap(data))
      .then(() => setOpenMatchedSwapDialog(true))
      .catch((e) => console.error("Error fetching matched swap:", e));
  };

  const toggleExpanded = () => {
    setExpanded((prev) => !prev);
  };

  if (isMobile) {
    return (
      <>
        <Card sx={{ mb: 2, boxShadow: "0px 4px 16px #60606040" }}>
          <CardContent sx={{ position: "relative" }}>
            <Box display="flex" justifyContent="space-between">
              <Chip label={props.index + (props.page - 1) * 10 + 1} />
              <Typography variant="body2">
                {props.swap?.updatedAt.split("T")[0]}
              </Typography>
            </Box>

            <Typography variant="h6" sx={{ mt: 1 }}>
              {props.swap?.Teacher?.Name}
            </Typography>
            <Typography variant="body2">
              {props.swap?.Teacher?.User.Phone}
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              {props.swap?.Subjects} - {props.swap?.Type}
            </Typography>
            <IconButton
              sx={{ position: "absolute", right: "5px", top: "2.2em" }}
              onClick={toggleExpanded}
            >
              {expanded ? (
                <ArrowForwardIos sx={{ rotate: "-90deg" }} />
              ) : (
                <ArrowForwardIos sx={{ rotate: "90deg" }} />
              )}
            </IconButton>

            {/* Content to be hidden/shown on expansion */}
            <Collapse in={expanded}>
              <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() =>
                    window.open(
                      `tel:+254${props.swap?.Teacher?.User?.Phone.slice(1)}`,
                      "_self"
                    )
                  }
                >
                  <Phone fontSize="small" />
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    window.open(
                      `sms:+254${props.swap?.Teacher?.User?.Phone.slice(1)}`,
                      "_self"
                    )
                  }
                >
                  <Message fontSize="small" />
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() =>
                    window.open(
                      `https://wa.me/+254${props.swap?.Teacher?.User?.Phone.slice(
                        1
                      )}`,
                      "_blank"
                    )
                  }
                >
                  <WhatsApp fontSize="small" />
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() =>
                    window.open(
                      `mailto:${props.swap?.Teacher?.User?.Email}`,
                      "_self"
                    )
                  }
                >
                  <Email fontSize="small" />
                </Button>
              </Box>
              <Divider sx={{ my: 2 }} />

              <Typography variant="body2" sx={{ mb: 1 }}>
                <strong>Current:</strong> {props.swap?.CountyFrom},{" "}
                {props.swap?.SubCountyFrom}, {props.swap?.WardFrom},{" "}
                {props.swap?.SchoolFrom}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                <strong>Desired:</strong> {props.swap?.Teacher?.CountyTo},{" "}
                {props.swap?.Teacher?.SubCountyTo},{" "}
                {props.swap?.Teacher?.WardTo}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                <strong>Matched:</strong> {props.swap?.CountyTo},{" "}
                {props.swap?.SubCountyTo}, {props.swap?.WardTo},{" "}
                {props.swap?.SchoolTo}
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                <strong>Status:</strong> {props.swap?.Status}
              </Typography>

              <Box display="flex" flexDirection="column">
                <Chip
                  sx={{ fontSize: "x-small", mb: 1 }}
                  label={
                    mePay
                      ? mePay.Status === "Pending"
                        ? "Pending Confirmation"
                        : mePay.Status
                      : "Not Paid"
                  }
                  color={
                    mePay
                      ? mePay.Status === "Confirmed"
                        ? "success"
                        : "warning"
                      : "error"
                  }
                />
              </Box>
              <Button
                variant="contained"
                onClick={handleClick}
                disabled={loading || !mePay || mePay.Status !== "Pending"}
                fullWidth
              >
                {loading ? <CircularProgress size={24} /> : getButtonText()}
              </Button>

              <Button
                variant="contained"
                color="info"
                onClick={handleViewMatchedSwap}
                fullWidth
                sx={{ mt: 2 }}
              >
                View Matched Swap
              </Button>
            </Collapse>
          </CardContent>
        </Card>

        <Dialog
          open={openMatchedSwapDialog}
          onClose={() => setOpenMatchedSwapDialog(false)}
        >
          <DialogTitle>Matched Swap Details</DialogTitle>
          <Divider />

          <DialogContent>
            {matchedSwap ? (
              <Box>
                <Typography marginTop={1} variant="body1" gutterBottom>
                  <strong>Name:</strong> {matchedSwap.Teacher?.Name}
                </Typography>
                <Grid2 container spacing={1}>
                  <Grid2 size={{ xs: 3 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() =>
                        window.open(
                          `tel:+254${matchedSwap.Teacher?.User?.Phone.slice(
                            1
                          )}`,
                          "_self"
                        )
                      }
                    >
                      <Phone fontSize="small" />
                    </Button>
                  </Grid2>

                  <Grid2 size={{ xs: 3 }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() =>
                        window.open(
                          `sms:+254${matchedSwap.Teacher?.User?.Phone.slice(
                            1
                          )}`,
                          "_self"
                        )
                      }
                    >
                      <Message fontSize="small" />
                    </Button>
                  </Grid2>

                  <Grid2 size={{ xs: 3 }}>
                    <Button
                      variant="contained"
                      color="success"
                      size="small"
                      onClick={() =>
                        window.open(
                          `https://wa.me/+254${matchedSwap.Teacher?.User?.Phone.slice(
                            1
                          )}`,
                          "_blank"
                        )
                      }
                    >
                      <WhatsApp fontSize="small" />
                    </Button>
                  </Grid2>

                  <Grid2 size={{ xs: 3 }}>
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={() =>
                        window.open(
                          `mailto:${matchedSwap.Teacher?.User?.Email}`,
                          "_self"
                        )
                      }
                    >
                      <Email fontSize="small" />
                    </Button>
                  </Grid2>
                </Grid2>
                <Divider sx={{ my: 2 }} />
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <strong>Teacher Details:</strong> {matchedSwap.Subjects} -{" "}
                  {matchedSwap.Type}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <strong>From Location:</strong> {matchedSwap.CountyFrom},{" "}
                  {matchedSwap.SubCountyFrom}, {matchedSwap.WardFrom},{" "}
                  {matchedSwap.SchoolFrom}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <strong>Desired Location:</strong>{" "}
                  {matchedSwap.Teacher?.CountyTo},{" "}
                  {matchedSwap.Teacher?.SubCountyTo},{" "}
                  {matchedSwap.Teacher?.WardTo}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <strong>To Location:</strong> {matchedSwap.CountyTo},{" "}
                  {matchedSwap.SubCountyTo}, {matchedSwap.WardTo}
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  <strong>Status:</strong> {matchedSwap.Status}
                </Typography>
              </Box>
            ) : (
              <CircularProgress />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenMatchedSwapDialog(false)}
              color="primary"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle color="primary">Review Payment</DialogTitle>
          <Divider />
          <DialogContent>
            <Typography
              sx={{ backgroundColor: "#f5f5f5", p: 1, borderRadius: "5px" }}
              gutterBottom
            >
              {mePay && mePay.MpesaCode}
            </Typography>
            <Select
              value={status}
              onChange={handleStatusChange}
              fullWidth
              gutterBottom
            >
              <MenuItem value="Confirmed">Confirmed</MenuItem>
              <MenuItem value="Payment Not Received">
                Payment Not Received
              </MenuItem>
              <MenuItem value="Amount Not Sufficient">
                Amount Not Sufficient
              </MenuItem>
              <MenuItem value="System Delay">System Delay</MenuItem>
            </Select>
            {alertMessage && (
              <Alert
                severity={alertMessage.type}
                onClose={() => setAlertMessage(null)}
              >
                {alertMessage.text}
              </Alert>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="primary">
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <Chip label={props.index + (props.page - 1) * 10 + 1}></Chip>
        </TableCell>

        <TableCell>
          <Typography variant="body2"> {props.swap?.Teacher?.Name}</Typography>
          <Typography variant="body2">
            {props.swap?.Teacher?.User?.Phone}
          </Typography>

          <Typography variant="body2">
            {props.swap?.Teacher?.User?.Email}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2"> {props.swap?.Subjects}</Typography>
          <Typography variant="body2"> {props.swap?.Type}</Typography>
        </TableCell>
        <TableCell sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
          <Chip
            sx={{
              fontStyle: "italic",
              fontSize: "x-small",
            }}
            label={
              "Current: " +
              props.swap?.CountyFrom +
              ", " +
              props.swap?.SubCountyFrom +
              ", " +
              props.swap?.WardFrom
            }
          />
          <Chip
            sx={{
              fontStyle: "italic",
              fontSize: "x-small",
            }}
            label={
              "Desired: " +
              props.swap?.Teacher?.CountyTo +
              ", " +
              props.swap?.Teacher?.SubCountyTo +
              ", " +
              props.swap?.Teacher?.WardTo
            }
          />
          <Chip
            sx={{
              fontStyle: "italic",
              fontSize: "x-small",
            }}
            label={
              "Matched: " +
              props.swap?.CountyTo +
              ", " +
              props.swap?.SubCountyTo +
              ", " +
              props.swap?.WardTo +
              ", "
            }
          />
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            {props.swap?.updatedAt.split("T")[0]}
          </Typography>
        </TableCell>
        <TableCell>
          <Chip
            sx={{ display: "flex", marginBottom: "5px" }}
            label={
              mePay
                ? mePay.Status === "Pending"
                  ? "Pending Confirmation"
                  : mePay.Status
                : "Not Paid"
            }
            color={
              mePay
                ? mePay.Status === "Confirmed"
                  ? "success"
                  : "warning"
                : "error"
            }
          />
        </TableCell>
        <TableCell sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Button
            variant="contained"
            size="small"
            onClick={handleClick}
            disabled={loading || !mePay || mePay.Status !== "Pending"}
          >
            {getButtonText()}
          </Button>
          <Button
            variant="contained"
            color="info"
            size="small"
            onClick={handleViewMatchedSwap}
          >
            View Matched Swap
          </Button>
        </TableCell>
      </TableRow>

      <Dialog
        open={openMatchedSwapDialog}
        onClose={() => setOpenMatchedSwapDialog(false)}
      >
        <DialogTitle>Matched Swap Details</DialogTitle>
        <Divider />

        <DialogContent>
          {matchedSwap ? (
            <Box>
              <Typography marginTop={1} variant="body1" gutterBottom>
                <strong>Name:</strong> {matchedSwap.Teacher?.Name}
              </Typography>
              <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    window.open(
                      `tel:+254${matchedSwap.Teacher?.User?.Phone.slice(1)}`,
                      "_self"
                    )
                  }
                >
                  <Phone />
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    window.open(
                      `sms:+254${matchedSwap.Teacher?.User?.Phone.slice(1)}`,
                      "_self"
                    )
                  }
                >
                  <Message />
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() =>
                    window.open(
                      `https://wa.me/+254${matchedSwap.Teacher?.User?.Phone.slice(
                        1
                      )}`,
                      "_blank"
                    )
                  }
                >
                  <WhatsApp />
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() =>
                    window.open(
                      `mailto:${matchedSwap.Teacher?.User?.Email}`,
                      "_self"
                    )
                  }
                >
                  <Email />
                </Button>
              </Box>
              <Divider sx={{ my: 2 }} />
              <Typography variant="body2" sx={{ mb: 1 }}>
                <strong>Teacher Details:</strong> {matchedSwap.Subjects} -{" "}
                {matchedSwap.Type}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                <strong>From Location:</strong> {matchedSwap.CountyFrom},{" "}
                {matchedSwap.SubCountyFrom}, {matchedSwap.WardFrom},{" "}
                {matchedSwap.SchoolFrom}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                <strong>Desired Location:</strong>{" "}
                {matchedSwap.Teacher?.CountyTo},{" "}
                {matchedSwap.Teacher?.SubCountyTo},{" "}
                {matchedSwap.Teacher?.WardTo}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                <strong>To Location:</strong> {matchedSwap.CountyTo},{" "}
                {matchedSwap.SubCountyTo}, {matchedSwap.WardTo}
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                <strong>Status:</strong> {matchedSwap.Status}
              </Typography>
            </Box>
          ) : (
            <CircularProgress />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenMatchedSwapDialog(false)}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
