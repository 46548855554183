import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { Controller } from "react-hook-form";
import countyData from "../../assets/data/county_data.json";

function LocationSelector({
  control,
  errors,
  countyName = "CurrentCounty",
  subCountyName = "CurrentSubCounty",
  wardName = "CurrentWard",
  defaultCounty,
  defaultSubCounty,
  defaultWard,
}) {
  const [selectedCounty, setSelectedCounty] = useState(defaultCounty || "");
  const [selectedSubCounty, setSelectedSubCounty] = useState(
    defaultSubCounty || ""
  );
  const [selectedWard, setSelectedWard] = useState(defaultWard || "");

  const [subCounties, setSubCounties] = useState([]);
  const [wards, setWards] = useState([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    if (selectedCounty) {
      setSubCounties(Object.keys(countyData[selectedCounty]));
      if (!isInitialLoad) {
        setSelectedSubCounty("");
        setSelectedWard("");
        setWards([]);
      }
    }
  }, [selectedCounty]);

  useEffect(() => {
    if (selectedSubCounty && selectedCounty) {
      setWards(countyData[selectedCounty][selectedSubCounty]);
      if (!isInitialLoad) {
        setSelectedWard("");
      }
    }
  }, [selectedSubCounty]);

  useEffect(() => {
    setIsInitialLoad(false);
  }, []);

  return (
    <>
      <Controller
        control={control}
        name={countyName}
        render={({ field }) => (
          <FormControl
            fullWidth
            margin="normal"
            error={Boolean(errors[countyName])}
          >
            <InputLabel>County</InputLabel>
            <Select
              {...field}
              value={selectedCounty}
              onChange={(e) => {
                setSelectedCounty(e.target.value);
                field.onChange(e.target.value);
              }}
            >
              {Object.keys(countyData).map((county) => (
                <MenuItem key={county} value={county}>
                  {county}
                </MenuItem>
              ))}
            </Select>
            {errors[countyName] && (
              <FormHelperText>{errors[countyName].message}</FormHelperText>
            )}
          </FormControl>
        )}
      />

      <Controller
        control={control}
        name={subCountyName}
        render={({ field }) => (
          <FormControl
            fullWidth
            margin="normal"
            error={Boolean(errors[subCountyName])}
            disabled={!selectedCounty}
          >
            <InputLabel>SubCounty</InputLabel>
            <Select
              {...field}
              value={selectedSubCounty}
              onChange={(e) => {
                setSelectedSubCounty(e.target.value);
                field.onChange(e.target.value);
              }}
            >
              {subCounties.map((subCounty) => (
                <MenuItem key={subCounty} value={subCounty}>
                  {subCounty}
                </MenuItem>
              ))}
            </Select>
            {errors[subCountyName] && (
              <FormHelperText>{errors[subCountyName].message}</FormHelperText>
            )}
          </FormControl>
        )}
      />

      <Controller
        control={control}
        name={wardName}
        render={({ field }) => (
          <FormControl
            fullWidth
            margin="normal"
            error={Boolean(errors[wardName])}
            disabled={!selectedSubCounty}
          >
            <InputLabel>Ward</InputLabel>
            <Select
              {...field}
              value={selectedWard}
              onChange={(e) => {
                setSelectedWard(e.target.value);
                field.onChange(e.target.value);
              }}
            >
              {wards.map((ward) => (
                <MenuItem key={ward} value={ward}>
                  {ward}
                </MenuItem>
              ))}
            </Select>
            {errors[wardName] && (
              <FormHelperText>{errors[wardName].message}</FormHelperText>
            )}
          </FormControl>
        )}
      />
    </>
  );
}

export default LocationSelector;
