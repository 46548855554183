import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress, // Import CircularProgress for loading spinner
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
  TextField,
  DialogActions,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Collapse,
} from "@mui/material";
import CreateUser from "../users/CreateUser";
import { DeleteDialog, EditDialog } from "./TeacherDialogs"; // Import dialogs for update and delete
import {
  ArrowForwardIos,
  Email,
  Message,
  Phone,
  WhatsApp,
} from "@mui/icons-material";

export default function Teachers(props) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false); // Add loading state
  const [currentPage, setCurrentPage] = useState(1);
  const [changed, setChanged] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // For wildcard search
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [column, setColumn] = useState("name");
  const [searchValue, setSearchValue] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setLoading(true); // Set loading to true before fetch
    setData(null);
    setSearchValue("");
    setColumn("name");
    fetch(`/api/teachers?offset=${(currentPage - 1) * 12}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch data!!!");
        }
        return res.json();
      })
      .then((data) => {
        setData(data);
        setLoading(false); // Set loading to false after fetch
      })
      .catch((err) => {
        setLoading(false); // Ensure loading is false on error
      });
  }, [changed, currentPage, searchQuery]);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage + 1); // TablePagination uses 0-based indexing
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setChanged(!changed); // Refresh data after user creation
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setChanged(!changed); // Refresh after deletion
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setSelectedTeacher(null);
    setChanged(!changed); // Refresh after edit
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value != "") {
      setData(null);
      fetch(`/api/teachers?searchBy=${column}&searchValue=${value}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw new Error();
        })
        .then((data) => {
          setData(data);
        })
        .catch(() => {});
    } else {
      setChanged(!changed);
    }
  };

  const renderTable = () => (
    <Card sx={{ boxShadow: "0px 4px 16px #60606040" }}>
      <Table sx={{ minWidth: "800px" }}>
        <TableHead>
          <TableRow>
            <TableCell>SN</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.users &&
            data.users.map((user, i) => (
              <TableRow key={user.UserID}>
                <TableCell
                  onClick={() => {
                    setSelectedTeacher(user);
                    setEditDialogOpen(true);
                  }}
                >
                  <Chip label={i + 1 + (currentPage - 1) * 12} />
                </TableCell>
                <TableCell
                  onClick={() => {
                    setSelectedTeacher(user);
                    setEditDialogOpen(true);
                  }}
                >
                  <Typography sx={{ textTransform: "capitalize" }}>
                    {user.Name}
                  </Typography>

                  <Typography variant="body2" sx={{ fontSize: "smaller" }}>
                    {user.User.Phone}
                  </Typography>
                </TableCell>
                <TableCell
                  onClick={() => {
                    setSelectedTeacher(user);
                    setEditDialogOpen(true);
                  }}
                >
                  <Typography variant="h6" sx={{ fontSize: "smaller" }}>
                    {user.Type}
                  </Typography>
                  <Divider />
                  <Typography
                    onClick={() => {
                      setSelectedTeacher(user);
                      setEditDialogOpen(true);
                    }}
                    variant="body2"
                  >
                    {user.Subjects}
                  </Typography>
                </TableCell>
                <TableCell
                  onClick={() => {
                    setSelectedTeacher(user);
                    setEditDialogOpen(true);
                  }}
                  sx={{ display: "flex", gap: 1, flexDirection: "column" }}
                >
                  <Chip
                    sx={{
                      fontStyle: "italic",
                      fontSize: "x-small",
                    }}
                    label={
                      "Current: " +
                      user.CurrentCounty +
                      ", " +
                      user.CurrentSubCounty +
                      ", " +
                      user.CurrentWard
                    }
                  />
                  <Chip
                    sx={{
                      fontStyle: "italic",
                      fontSize: "x-small",
                    }}
                    label={
                      "Desired: " +
                      user.CountyTo +
                      ", " +
                      user.SubCountyTo +
                      ", " +
                      user.WardTo
                    }
                  />
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      title={user.User.Phone}
                      onClick={() =>
                        window.open(
                          `tel:+254${user.User.Phone.slice(1)}`,
                          "_self"
                        )
                      }
                    >
                      <Phone fontSize="small" />
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      title={user.User.Phone}
                      onClick={() =>
                        window.open(
                          `sms:+254${user.User.Phone.slice(1)}`,
                          "_self"
                        )
                      }
                    >
                      <Message fontSize="small" />
                    </Button>
                    <Button
                      variant="contained"
                      color="success"
                      size="small"
                      title={user.User.Phone}
                      onClick={() =>
                        window.open(
                          `https://wa.me/+254${user.User.Phone.slice(1)}`,
                          "_blank"
                        )
                      }
                    >
                      <WhatsApp fontSize="small" />
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      title={user.Email}
                      onClick={() =>
                        window.open(`mailto:${user.User.Email}`, "_self")
                      }
                    >
                      <Email fontSize="small" />
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Card>
  );

  const RenderCards = (params) => {
    return (
      <Stack spacing={2}>
        {data &&
          data.users &&
          data.users.map((user, i) => (
            <CardItem key={i} currentPage={currentPage} user={user} i={i} />
          ))}
      </Stack>
    );
  };

  const renderContent = () => {
    if (loading) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }} // Set minimum height to ensure proper layout
        >
          <CircularProgress />
        </Box>
      );
    }

    return isMobile ? RenderCards() : renderTable();
  };

  return (
    <Box marginTop={8}>
      <Stack spacing={3}>
        <Box sx={{ display: "flex", gap: 2 }}>
          <FormControl fullWidth>
            <InputLabel size="small">Search by...</InputLabel>
            <Select
              label="Search by..."
              size="small"
              onChange={(e) => setColumn(e.target.value)}
              value={column}
            >
              <MenuItem value="name">Name</MenuItem>
              <MenuItem value="Subjects">Subjects</MenuItem>
              <MenuItem value="phoneNumber">Phone</MenuItem>
              <MenuItem value="email">Email</MenuItem>
              <MenuItem value="CurrentCounty">Current County</MenuItem>
              <MenuItem value="CurrentSubCounty">Current SubCounty</MenuItem>
              <MenuItem value="CurrentWard">Current Ward</MenuItem>
              <MenuItem value="CountyTo">Desired County</MenuItem>
              <MenuItem value="SubCountyTo">Desired SubCounty</MenuItem>
              <MenuItem value="WardTo">Desired Ward</MenuItem>
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            size="small"
            label="Search..."
            value={searchValue}
            fullWidth
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Box>

        <Box sx={{ minHeight: 400 }}>{renderContent()}</Box>
        <Divider />
        <TablePagination
          component="div"
          count={data != null ? data.total : 0}
          page={currentPage - 1}
          onPageChange={handlePageChange}
          rowsPerPage={12}
          rowsPerPageOptions={[12]}
          siblingCount={1}
          boundaryCount={1}
          showFirstButton
          showLastButton
        />
      </Stack>
      <CreateUser open={dialogOpen} onClose={handleDialogClose} />
      <DeleteDialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        teacher={selectedTeacher}
      />
      <EditDialog
        open={editDialogOpen}
        onClose={handleEditDialogClose}
        teacher={selectedTeacher}
        openDeleteDialog={() => {
          setDeleteDialogOpen(true);
          setEditDialogOpen(false);
        }}
      />
    </Box>
  );
}

const CardItem = ({ user, i, currentPage }) => {
  const [expanded, setExpanded] = useState(true);
  const toggleExpanded = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <Card
      key={user.UserID}
      sx={{
        padding: 2,
        boxShadow: "0px 4px 16px #60606040",
        position: "relative",
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Chip label={i + 1 + (currentPage - 1) * 12} />
      </Box>
      <Typography
        variant="title"
        sx={{ mt: 1, fontWeight: "500", textTransform: "capitalize" }}
      >
        {user?.Name}
      </Typography>
      <Typography variant="body2">{user?.User.Phone}</Typography>
      <Typography variant="body2" sx={{ mb: 1 }}>
        {user?.Subjects} - {user?.Type}
      </Typography>
      <IconButton
        sx={{ position: "absolute", right: "5px", top: "10px" }}
        onClick={toggleExpanded}
      >
        {expanded ? (
          <ArrowForwardIos sx={{ rotate: "-90deg" }} />
        ) : (
          <ArrowForwardIos sx={{ rotate: "90deg" }} />
        )}
      </IconButton>
      <Collapse in={expanded}>
        <Divider sx={{ my: 1 }} />
        <Typography variant="body2" sx={{ mb: 1 }}>
          <strong>Current:</strong> {user.CurrentCounty},{" "}
          {user.CurrentSubCounty}, {user.CurrentWard}, {user.CurrentSchool}
        </Typography>
        <Typography variant="body2" sx={{ mb: 1 }}>
          <strong>Desired:</strong> {user.CountyTo}, {user.SubCountyTo},{" "}
          {user.WardTo}
        </Typography>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              title={user?.User.Phone}
              onClick={() =>
                window.open(`tel:+254${user?.User.Phone.slice(1)}`, "_self")
              }
            >
              <Phone fontSize="small" />
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              title={user?.User.Phone}
              onClick={() =>
                window.open(`sms:+254${user?.User.Phone.slice(1)}`, "_self")
              }
            >
              <Message fontSize="small" />
            </Button>
            <Button
              variant="contained"
              color="success"
              size="small"
              title={user?.User.Phone}
              onClick={() =>
                window.open(
                  `https://wa.me/+254${user?.User.Phone.slice(1)}`,
                  "_blank"
                )
              }
            >
              <WhatsApp fontSize="small" />
            </Button>
            <Button
              variant="contained"
              color="error"
              size="small"
              title={user.User.Email}
              onClick={() => window.open(`mailto:${user.User.Email}`, "_self")}
            >
              <Email fontSize="small" />
            </Button>
          </Box>
        </Box>{" "}
      </Collapse>
    </Card>
  );
};
