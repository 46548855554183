import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Divider,
  Stack,
  Typography,
  Button,
  CircularProgress,
  IconButton,
  Chip,
  TablePagination,
  TextField,
  DialogActions,
} from "@mui/material";
import dayjs from "dayjs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { debounce } from "lodash";
import { Email, Message, Phone, WhatsApp } from "@mui/icons-material";

export default function Messages(props) {
  const [messages, setMessages] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [expandedMessageId, setExpandedMessageId] = useState(null);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetchMessages();
  }, [page, rowsPerPage, searchText]);

  const fetchMessages = () => {
    setLoading(true);
    const url = searchText
      ? `/api/messages/wildsearch/${searchText}`
      : `/api/messages/mymessages/${rowsPerPage}/${page * rowsPerPage}`;
    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        else if (res.status === 401) {
          window.location.href = "/";
        } else throw new Error("Error fetching messages");
      })
      .then((data) => {
        setLoading(false);
        setMessages(data?.data);
        setCount(data?.total);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const handleExpandClick = (message) => {
    setExpandedMessageId(expandedMessageId === message.ID ? null : message.ID);

    if (!message.Status) {
      fetch(`/api/messages/${message.ID}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ Status: true }),
      })
        .then((res) => {
          if (res.ok) {
            setMessages((prevMessages) =>
              prevMessages.map((msg) =>
                msg.ID === message.ID ? { ...msg, Status: true } : msg
              )
            );
          } else {
            throw new Error("Error updating message status");
          }
        })
        .catch((e) => {
          console.error("Failed to update message status:", e);
        });
    }
  };

  const handleSearchChange = debounce((event) => {
    setSearchText(event.target.value);
    setPage(0);
  }, 500);

  return (
    <Box marginTop={8}>
      <Stack spacing={3}>
        <Box
          sx={{
            display: { md: "flex", xs: "block" },
            alignItems: "center",
          }}
        >
          <Typography sx={{ flexGrow: 1 }} variant="h5" gutterBottom>
            My Messages
          </Typography>
          <TextField
            label="Search..."
            variant="outlined"
            size="small"
            fullWidth="xs"
            onChange={handleSearchChange}
            sx={{ marginRight: 2 }}
          />
        </Box>
        <Stack sx={{ minHeight: "50vh" }} spacing={2}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            messages.map((message) => (
              <Card
                key={message.ID}
                sx={{
                  borderRadius: "16px",
                  boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
                  backgroundColor:
                    expandedMessageId === message.ID ? "#f5f5f5" : "white",
                  padding: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: { md: "flex", xs: "block" },
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <Chip
                      variant={message.Status ? "outlined" : "contained"}
                      label={
                        message.createdAt.split("T")[0] +
                        " ~ " +
                        message.createdAt.split("T")[1].substring(0, 5)
                      }
                    ></Chip>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: message.Status ? "normal" : "bold",
                      }}
                    >
                      {message.Subject}
                    </Typography>
                  </Box>

                  <IconButton onClick={() => handleExpandClick(message)}>
                    <ExpandMoreIcon />
                  </IconButton>
                </Box>
                {expandedMessageId !== message.ID && (
                  <Typography my={1} color="default" variant="body2">
                    {message.Message}
                  </Typography>
                )}
                <Collapse
                  in={expandedMessageId === message.ID}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box sx={{ padding: 1 }}>
                    <Typography variant="body1" color="textSecondary">
                      {message.Message}
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                    <Box
                      sx={{
                        display: { dm: "flex", xs: "block" },
                        gap: 1,
                        textStyle: "italic",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="body2" color="textSecondary">
                        {message.Name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        -
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {message.Email}
                      </Typography>
                      <Box sx={{ flexGrow: 1 }}></Box>
                      <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() =>
                            window.open(
                              `tel:+254${message.Phone.slice(1)}`,
                              "_self"
                            )
                          }
                        >
                          <Phone />
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          onClick={() =>
                            window.open(
                              `sms:+254${message.Phone.slice(1)}`,
                              "_self"
                            )
                          }
                        >
                          <Message />
                        </Button>
                        <Button
                          variant="contained"
                          color="success"
                          size="small"
                          onClick={() =>
                            window.open(
                              `https://wa.me/+254${message.Phone.slice(1)}`,
                              "_blank"
                            )
                          }
                        >
                          <WhatsApp />
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          size="small"
                          onClick={() =>
                            window.open(`mailto:${message.Email}`, "_self")
                          }
                        >
                          <Email />
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Collapse>
              </Card>
            ))
          )}
        </Stack>
        <Divider />
        <TablePagination
          component="div"
          count={count}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Stack>
    </Box>
  );
}
