import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  InputLabel,
  Typography,
  CircularProgress,
  Alert,
  Select,
  MenuItem,
  Stack,
  OutlinedInput,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import LocationSelector from "./LocationSelector"; // Adjust the path based on your project structure
import countyData from "../../assets/data/county_data.json"; // Adjust the path based on your project structure

const typeOptions = ["Secondary", "JSS", "Primary"];
const subjectOptions = [
  "English",
  "Literature",
  "Mathematics",
  "Kiswahili",
  "Physics",
  "Chemistry",
  "Biology",
  "History",
  "Geography",
  "CRE",
  "IRE",
  "HRE",
  "Business Studies",
  "French",
  "German",
  "Arabic",
  "Kenya Sign Language",
  "Music",
  "Home Science",
  "Art & Design",
  "Agriculture",
  "Metal Work",
  "Wood Work",
  "Building Construction",
  "Electricity",
  "Power Mechanic",
  "Drawing & Design",
  "Aviation Technology",
  "Computer Studies",
];

export function DeleteDialog({ open, onClose, teacher }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleDelete = () => {
    setLoading(true);
    fetch(`/api/teachers/${teacher.ID}`, {
      method: "DELETE",
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) throw Error("Failed to delete teacher");
        else {
          setError("Teacher was successful!");
          setTimeout(() => {
            onClose();
          }, 1000);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Teacher deletion failed!");
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Teacher</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete {teacher?.Name}? This action cannot be
          undone.
        </DialogContentText>
        {error && (
          <Alert color={error.includes("success") ? "success" : "warning"}>
            {error}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleDelete} color="error">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function EditDialog({ open, onClose, teacher, openDeleteDialog }) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [teacherData, setTeacherData] = useState(null);
  const [teacherError, setTeacherError] = useState(null);
  const [isTeacherLoading, setIsTeacherLoading] = useState(false);

  // Fetch teacher profile on load
  useEffect(() => {
    setTeacherError(null);
    setTeacherData(null);
    setValue();
    setValue();
    setValue();
    if (teacher) {
      setTeacherData(teacher);
      setValue("CurrentCounty", teacher.CurrentCounty || "");
      setValue("CurrentSubCounty", teacher.CurrentSubCounty || "");
      setValue("CurrentWard", teacher.CurrentWard || "");
    }
  }, [teacher]);

  const handleTeacherSubmit = (data) => {
    setIsTeacherLoading(true);

    fetch(`/api/teachers/profile?teacherID=${teacher.ID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...teacherData, ...data }),
    })
      .then((response) => response.json())
      .then(() => {
        setIsTeacherLoading(false);
        setTeacherError("Teacher profile updated successfully!");
      })
      .catch(() => {
        setIsTeacherLoading(false);
        setTeacherError("Failed to update teacher profile.");
      });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Edit Teacher</DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <form onSubmit={handleSubmit(handleTeacherSubmit)}>
            <Card
              style={{
                borderRadius: "16px",
                boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
              }}
            >
              <CardHeader
                subheader="Update teacher profile"
                title="Teacher Profile"
              />
              <Divider />
              <CardContent>
                <Stack spacing={3} sx={{ maxWidth: "sm" }}>
                  <FormControl fullWidth>
                    <InputLabel shrink={Boolean(teacherData?.Name)}>
                      Name
                    </InputLabel>
                    <OutlinedInput
                      label="Name"
                      value={teacherData?.Name || ""}
                      onChange={(e) =>
                        setTeacherData({ ...teacherData, Name: e.target.value })
                      }
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel shrink={Boolean(teacherData?.Type)}>
                      Type
                    </InputLabel>
                    <Select
                      label="Type"
                      value={teacherData?.Type || ""}
                      onChange={(e) =>
                        setTeacherData({ ...teacherData, Type: e.target.value })
                      }
                    >
                      {typeOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel>Subjects</InputLabel>
                    <Select
                      multiple
                      value={
                        teacherData && teacherData?.Subjects !== undefined
                          ? teacherData?.Subjects.split(" & ")
                          : []
                      }
                      onChange={(e) => {
                        let selectedSubjects = [...e.target.value];

                        if (selectedSubjects.length > 2) {
                          selectedSubjects = selectedSubjects.slice(-2);
                        }

                        selectedSubjects.sort();

                        setTeacherData({
                          ...teacherData,
                          Subjects: selectedSubjects.join(" & "),
                        });
                      }}
                      renderValue={(selected) => selected.join(" & ")}
                    >
                      {subjectOptions.map((subject) => (
                        <MenuItem key={subject} value={subject}>
                          {subject}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {teacherData && (
                    <LocationSelector
                      control={control}
                      errors={errors}
                      defaultCounty={teacherData.CurrentCounty || ""}
                      defaultSubCounty={teacherData.CurrentSubCounty || ""}
                      defaultWard={teacherData.CurrentWard || ""}
                    />
                  )}

                  {teacherError && (
                    <Alert
                      severity={
                        teacherError.includes("success") ? "success" : "error"
                      }
                    >
                      {teacherError}
                    </Alert>
                  )}
                </Stack>
              </CardContent>
              <Divider />
              <CardActions sx={{ justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isTeacherLoading}
                >
                  {isTeacherLoading ? <CircularProgress size={24} /> : "Update"}
                </Button>
              </CardActions>
            </Card>
          </form>

          {teacherData && <SwapDetails currentUser={teacherData} />}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ textTransform: "capitalize" }}
          onClick={onClose}
          variant="outlined"
        >
          Close
        </Button>
        <Button
          sx={{ textTransform: "capitalize" }}
          onClick={openDeleteDialog}
          color="error"
          variant="contained"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const SwapDetails = (props) => {
  const [isError, setIsError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCounty, setSelectedCounty] = useState(
    props.currentUser.CountyTo ? props.currentUser.CountyTo : ""
  );
  const [selectedSubCounty, setSelectedSubCounty] = useState(
    props.currentUser.SubCountyTo ? props.currentUser.SubCountyTo : ""
  );
  const [selectedWard, setSelectedWard] = useState(
    props.currentUser.WardTo ? props.currentUser.WardTo : ""
  );

  useEffect(() => {
    if (props.currentUser) {
      setSelectedCounty(props.currentUser.CountyTo);
      setSelectedSubCounty(props.currentUser.SubCountyTo);
      setSelectedWard(props.currentUser.WardTo);
    }
  }, [props.currentUser]);

  const handleCountyChange = (event) => {
    setSelectedCounty(event.target.value);
    setSelectedSubCounty("");
    setSelectedWard("");
  };

  const handleSubCountyChange = (event) => {
    setSelectedSubCounty(event.target.value);
    setSelectedWard("");
  };

  const handleWardChange = (event) => {
    setSelectedWard(event.target.value);
  };

  const editDetails = () => {
    const details = {
      CountyTo: selectedCounty,
      SubCountyTo: selectedSubCounty,
      WardTo: selectedWard,
    };

    if (!details.CountyTo) {
      return setIsError("Please select a county.");
    }
    if (!details.SubCountyTo) {
      return setIsError("Please select a subcounty.");
    }
    if (!details.WardTo) {
      return setIsError("Please select a ward.");
    }

    setIsLoading(true);
    setIsError("");

    // Make API call to update the user details
    fetch(`/api/teachers/profile?teacherID=${props.currentUser.ID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(details),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Update of swap details failed");
        }
      })
      .then((data) => {
        setIsLoading(false);
        setIsError("Updated successfully");
      })
      .catch((err) => {
        setIsLoading(false);
        setIsError("Update failed!");
      });
  };

  return (
    <Box>
      <Card
        style={{
          borderRadius: "16px",
          boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
        }}
      >
        <CardHeader
          subheader="Where would you like to go?"
          title="Swap Preferences"
        />
        <Divider />
        <CardContent>
          <Typography variant="h6" gutterBottom></Typography>
          <Box
            component="form"
            onSubmit={(e) => e.preventDefault()}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            {/* County */}
            <FormControl fullWidth margin="normal">
              <InputLabel>Preferred County*</InputLabel>
              <Select
                value={selectedCounty}
                onChange={handleCountyChange}
                label="Preferred County"
              >
                {Object.keys(countyData).map((county) => (
                  <MenuItem key={county} value={county}>
                    {county}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* SubCounty */}
            <FormControl fullWidth margin="normal" disabled={!selectedCounty}>
              <InputLabel>Preferred SubCounty*</InputLabel>
              <Select
                value={selectedSubCounty}
                onChange={handleSubCountyChange}
                label="Preferred SubCounty"
              >
                {selectedCounty &&
                  Object.keys(countyData[selectedCounty]).map((subCounty) => (
                    <MenuItem key={subCounty} value={subCounty}>
                      {subCounty}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            {/* Ward */}
            <FormControl
              fullWidth
              margin="normal"
              disabled={!selectedSubCounty}
            >
              <InputLabel>Preferred Ward*</InputLabel>
              <Select
                value={selectedWard}
                onChange={handleWardChange}
                label="Preferred Ward"
              >
                {selectedCounty &&
                  selectedSubCounty &&
                  countyData[selectedCounty][selectedSubCounty].map((ward) => (
                    <MenuItem key={ward} value={ward}>
                      {ward}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            {isError && (
              <Alert
                color={isError.includes("success") ? "success" : "error"}
                sx={{ mb: 2 }}
              >
                {isError}
              </Alert>
            )}
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={editDetails}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Save Changes"}
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};
